import { createReducer } from "@reduxjs/toolkit";
import { initialState, StepFormState } from "./types";
import { addThreadUser, clearFragmentState, clearStepFormState, createComment, getCampaignData, getCta, getEmailSubtypes, getEmailTypes, getEventDetails, getFragmentAttachments, getFragmentData, getFragmentRetroplanning, getImrDetails, getIntegrationTeamMembers, getKeyMessages, getKpis, getLinkedFragments, getSenders, getStaff, getStepAdminData, getStepAttachments, getStepComments, getStepCtaData, getStepData, getStepFragmentsData, getStepOptionsData, getStepRetroplanningData, getSurveys, getTags, getTodoList, getUsers, saveFragmentData, saveStepData, updateReminderDraft } from "./actions";

export default createReducer(initialState as StepFormState, builder => {
    builder
        .addCase(clearStepFormState, state => {  
            state.step = initialState.step;
            state.fragment = initialState.fragment;
            state.tags = initialState.tags;
            state.cta = initialState.cta;
            state.staff = initialState.staff;
            state.keyMessages = initialState.keyMessages;
            state.surveys = initialState.surveys;
            state.eventDetails = initialState.eventDetails;
            state.imrDetails = initialState.imrDetails;
            state.linkedFragments = initialState.linkedFragments;
            state.users = initialState.users;
            state.integrationTeam = initialState.integrationTeam;
            state.reminderDraft = initialState.reminderDraft;
            state.kpis = initialState.kpis;
            state.campaign = initialState.campaign;
            state.stepComments = initialState.stepComments;
        })

        .addCase(clearFragmentState, state => {
            state.fragment = initialState.fragment;
        })

        .addCase(getCampaignData.pending, state => {
            state.campaign.pending = true;
        })
        .addCase(getCampaignData.fulfilled, (state, { payload }) => {
            state.campaign.pending = false;
            state.campaign.fullfilled = true;
            state.campaign.data = payload;
        })
        .addCase(getCampaignData.rejected, state => {
            state.campaign.pending = false;
            state.campaign.error = true;
        })

        .addCase(getStepData.pending, state => {
            state.step.pending = true
        })
        .addCase(getStepData.fulfilled, (state, { payload }) => {
            state.step.pending = false;
            state.step.fullfilled = true;
            state.step.data = {
                ...state.step.data,
                ...payload
            };
        })
        .addCase(getStepData.rejected, state => {
            state.step.pending = false;
            state.step.error = true;
        })

        .addCase(getStepAdminData.pending, state => {
            state.step.admin.pending = true
        })
        .addCase(getStepAdminData.fulfilled, (state, { payload }) => {
            state.step.admin.pending = false;
            state.step.admin.fullfilled = true;
            state.step.data = {
                ...state.step.data,
                ...payload
            }
        })
        .addCase(getStepAdminData.rejected, state => {
            state.step.admin.pending = false;
            state.step.admin.error = true;
        })

        .addCase(getStepRetroplanningData.pending, state => {
            state.step.retroplanning.pending = true
        })
        .addCase(getStepRetroplanningData.fulfilled, (state, { payload }) => {
            state.step.retroplanning.pending = false;
            state.step.retroplanning.fullfilled = true;
            state.step.data = {
                ...state.step.data,
                ...payload
            }
        })
        .addCase(getStepRetroplanningData.rejected, state => {
            state.step.retroplanning.pending = false;
            state.step.retroplanning.error = true;
        })

        .addCase(getStepOptionsData.pending, state => {
            state.step.options.pending = true
        })
        .addCase(getStepOptionsData.fulfilled, (state, { payload }) => {
            state.step.options.pending = false;
            state.step.options.fullfilled = true;
            state.step.data = {
                ...state.step.data,
                ...payload
            }
        })
        .addCase(getStepOptionsData.rejected, state => {
            state.step.options.pending = false;
            state.step.options.error = true;
        })

        .addCase(getStepCtaData.pending, state => {
            state.step.cta.pending = true
        })
        .addCase(getStepCtaData.fulfilled, (state, { payload }) => {
            state.step.cta.pending = false;
            state.step.cta.fullfilled = true;
            state.step.data = {
                ...state.step.data,
                ...payload
            }
        })
        .addCase(getStepCtaData.rejected, state => {
            state.step.cta.pending = false;
            state.step.cta.error = true;
        })

        .addCase(getStepFragmentsData.pending, state => {
            state.step.fragments.pending = true
        })
        .addCase(getStepFragmentsData.fulfilled, (state, { payload }) => {
            state.step.fragments.pending = false;
            state.step.fragments.fullfilled = true;
            state.step.data = {
                ...state.step.data,
                ...payload
            }
        })
        .addCase(getStepFragmentsData.rejected, state => {
            state.step.fragments.pending = false;
            state.step.fragments.error = true;
        })

        .addCase(saveStepData.pending, state => {
            state.step.pending = true;
        })
        .addCase(saveStepData.fulfilled, (state, { payload }) => {
            state.step.pending = false;
            state.step.fullfilled = true;
            state.step.data = payload;

            state.step.admin = initialState.step.admin;
            state.step.retroplanning = initialState.step.retroplanning;
            state.step.options = initialState.step.options;
            state.step.cta = initialState.step.cta;
            state.step.fragments = initialState.step.fragments;
            state.stepComments = initialState.stepComments;
        })
        .addCase(saveStepData.rejected, state => {
            state.step.pending = false;
            state.step.error = true;
        })

        .addCase(getFragmentData.pending, state => {
            state.fragment.pending = true
        })
        .addCase(getFragmentData.fulfilled, (state, { payload }) => {
            state.fragment.pending = false;
            state.fragment.fullfilled = true;
            state.fragment.data = payload;
        })
        .addCase(getFragmentData.rejected, state => {
            state.fragment.pending = false;
            state.fragment.error = true;
        })

        .addCase(saveFragmentData.pending, state => {
            state.fragment.pending = true;
        })
        .addCase(saveFragmentData.fulfilled, (state, { payload }) => {
            state.fragment.pending = false;
            state.fragment.fullfilled = true;
            state.fragment.data = payload;

            state.stepComments = initialState.stepComments;
        })
        .addCase(saveFragmentData.rejected, state => {
            state.fragment.pending = false;
            state.fragment.error = true;
        })

        .addCase(getTags.pending, state => {
            state.tags.pending = true;
        })
        .addCase(getTags.fulfilled, (state, { payload }) => {
            state.tags.pending = false;
            state.tags.fullfilled = true;
            state.tags.data = payload;
        })
        .addCase(getTags.rejected, state => {
            state.tags.pending = false;
            state.tags.error = true;
        })

        .addCase(getCta.pending, state => {
            state.cta.pending = true;
        })
        .addCase(getCta.fulfilled, (state, { payload }) => {
            state.cta.pending = false;
            state.cta.fullfilled = true;
            state.cta.data = payload;
        })
        .addCase(getCta.rejected, state => {
            state.cta.pending = false;
            state.cta.error = true;
        })

        .addCase(getStaff.pending, state => {
            state.staff.pending = true;
        })
        .addCase(getStaff.fulfilled, (state, { payload }) => {
            state.staff.pending = false;
            state.staff.fullfilled = true;
            state.staff.data = payload;
        })
        .addCase(getStaff.rejected, state => {
            state.staff.pending = false;
            state.staff.error = true;
        })

        .addCase(getKeyMessages.pending, state => {
            state.keyMessages.pending = true;
        })
        .addCase(getKeyMessages.fulfilled, (state, { payload }) => {
            state.keyMessages.pending = false;
            state.keyMessages.fullfilled = true;
            state.keyMessages.data = payload;
        })
        .addCase(getKeyMessages.rejected, state => {
            state.keyMessages.pending = false;
            state.keyMessages.error = true;
        })

        .addCase(getSurveys.pending, state => {
            state.surveys.pending = true;
        })
        .addCase(getSurveys.fulfilled, (state, { payload }) => {
            state.surveys.pending = false;
            state.surveys.fullfilled = true;
            state.surveys.data = payload;
        })
        .addCase(getSurveys.rejected, state => {
            state.surveys.pending = false;
            state.surveys.error = true;
        })

        .addCase(getEventDetails.pending, state => {
            state.eventDetails.pending = true;
        })
        .addCase(getEventDetails.fulfilled, (state, { payload }) => {
            state.eventDetails.pending = false;
            state.eventDetails.fullfilled = true;
            state.eventDetails.data = payload;
        })
        .addCase(getEventDetails.rejected, state => {
            state.eventDetails.pending = false;
            state.eventDetails.error = true;
        })

        .addCase(getImrDetails.pending, state => {
            state.imrDetails.pending = true;
        })
        .addCase(getImrDetails.fulfilled, (state, { payload }) => {
            state.imrDetails.pending = false;
            state.imrDetails.fullfilled = true;
            state.imrDetails.data = payload;
        })
        .addCase(getImrDetails.rejected, state => {
            state.imrDetails.pending = false;
            state.imrDetails.error = true;
        })

        .addCase(getLinkedFragments.pending, state => {
            state.linkedFragments.pending = true;
        })
        .addCase(getLinkedFragments.fulfilled, (state, { payload }) => {
            state.linkedFragments.pending = false;
            state.linkedFragments.fullfilled = true;
            state.linkedFragments.data = payload;
        })
        .addCase(getLinkedFragments.rejected, state => {
            state.linkedFragments.pending = false;
            state.linkedFragments.error = true;
        })

        .addCase(getEmailTypes.pending, state => {
            state.emailTypes.pending = true;
        })
        .addCase(getEmailTypes.fulfilled, (state, { payload }) => {
            state.emailTypes.pending = false;
            state.emailTypes.fullfilled = true;
            state.emailTypes.data = payload;
        })
        .addCase(getEmailTypes.rejected, state => {
            state.emailTypes.pending = false;
            state.emailTypes.error = true;
        })

        .addCase(getEmailSubtypes.pending, state => {
            state.emailSubtypes.pending = true;
        })
        .addCase(getEmailSubtypes.fulfilled, (state, { payload }) => {
            state.emailSubtypes.pending = false;
            state.emailSubtypes.fullfilled = true;
            state.emailSubtypes.data = payload;
        })
        .addCase(getEmailSubtypes.rejected, state => {
            state.emailSubtypes.pending = false;
            state.emailSubtypes.error = true;
        })

        .addCase(getUsers.pending, state => {
            state.users.pending = true;
        })
        .addCase(getUsers.fulfilled, (state, { payload }) => {
            state.users.pending = false;
            state.users.fullfilled = true;
            state.users.data = payload;
        })
        .addCase(getUsers.rejected, state => {
            state.users.pending = false;
            state.users.error = true;
        })

        .addCase(getIntegrationTeamMembers.pending, state => {
            state.integrationTeam.pending = true;
        })
        .addCase(getIntegrationTeamMembers.fulfilled, (state, { payload }) => {
            state.integrationTeam.pending = false;
            state.integrationTeam.fullfilled = true;
            state.integrationTeam.data = payload;
        })
        .addCase(getIntegrationTeamMembers.rejected, state => {
            state.integrationTeam.pending = false;
            state.integrationTeam.error = true;
        })

        .addCase(getTodoList.pending, state => {
            state.todoList.pending = true;
        })
        .addCase(getTodoList.fulfilled, (state, { payload }) => {
            state.todoList.pending = false;
            state.todoList.fullfilled = true;
            state.todoList.data = payload;
        })
        .addCase(getTodoList.rejected, state => {
            state.todoList.pending = false;
            state.todoList.error = true;
        })

        .addCase(getSenders.pending, state => {
            state.senders.pending = true;
        })
        .addCase(getSenders.fulfilled, (state, { payload }) => {
            state.senders.pending = false;
            state.senders.fullfilled = true;
            state.senders.data = payload;
        })
        .addCase(getSenders.rejected, state => {
            state.senders.pending = false;
            state.senders.error = true;
        })

        .addCase(updateReminderDraft, (state, { payload }) => {
            state.reminderDraft = payload
        })

        .addCase(getKpis.pending, state => {
            state.kpis.pending = true;

        })
        .addCase(getKpis.fulfilled, (state, { payload }) => {
            state.kpis.pending = false;
            state.kpis.fullfilled = true;
            state.kpis.data = payload;
        })
        .addCase(getKpis.rejected, state => {
            state.kpis.pending = false;
            state.kpis.error = true;
        })

        .addCase(getFragmentRetroplanning.pending, state => {
            state.fragmentRetroplanning.pending = true;

        })
        .addCase(getFragmentRetroplanning.fulfilled, (state, { payload }) => {
            state.fragmentRetroplanning.pending = false;
            state.fragmentRetroplanning.fullfilled = true;
            state.fragmentRetroplanning.data = payload;
        })
        .addCase(getFragmentRetroplanning.rejected, state => {
            state.fragmentRetroplanning.pending = false;
            state.fragmentRetroplanning.error = true;
        })

        .addCase(getStepComments.pending, state => {
            state.stepComments.pending = true;

        })
        .addCase(getStepComments.fulfilled, (state, { payload }) => {
            state.stepComments.pending = false;
            state.stepComments.fullfilled = true;
            state.stepComments.data = payload;
        })
        .addCase(getStepComments.rejected, state => {
            state.stepComments.pending = false;
            state.stepComments.error = true;
        })

        .addCase(createComment.pending, state => {
            state.stepComments.pending = true;
          })
        .addCase(createComment.fulfilled, (state, { payload }) => {
            state.stepComments.pending = false;
            state.stepComments.data = [...state.stepComments.data, payload];
        })
        .addCase(createComment.rejected, state => {
            state.stepComments.pending = false;
            state.stepComments.error = true;
        })

        .addCase(addThreadUser.fulfilled, (state, { payload }) => {
            state.step.data = {
                ...state.step.data,
                payload: payload.payload
            }
        })

        .addCase(getStepAttachments.pending, state => {
            state.step.pending = true
        })
        .addCase(getStepAttachments.fulfilled, (state, { payload }) => {
            state.step.pending = false;
            state.step.fullfilled = true;
            state.step.data = {
                ...state.step.data,
                attachments: payload.attachments
            }
        })
        .addCase(getStepAttachments.rejected, state => {
            state.step.pending = false;
            state.step.error = true;
        })

        .addCase(getFragmentAttachments.pending, state => {
            state.fragment.pending = true
        })
        .addCase(getFragmentAttachments.fulfilled, (state, { payload }) => {
            state.fragment.pending = false;
            state.fragment.fullfilled = true;
            state.fragment.data = {
                ...state.fragment.data,
                attachments: payload.attachments
            }
        })
        .addCase(getFragmentAttachments.rejected, state => {
            state.fragment.pending = false;
            state.fragment.error = true;
        })
});